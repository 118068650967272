<template>
  <div id="container">
    <div class="toolbar">
      <div class="pdf-name">111111</div>
      <div class="pager-content">
        <input
          v-model="currentPage"
          class="pager"
          oninput="value=value.replace(/[^\d.]/g,'')"
          @blur="inputEvent"
        />
        <span class="split">/</span>
        <span class="pager-count">{{ pageCount }}</span>
        <div class="line"></div>
        <i
          class="el-icon-minus icon"
          title="缩小"
          @click="scaleChange('prev')"
        />
        <input
          v-model="scale"
          class="pager scale"
          oninput="value=value.replace(/[^0-9%]/g,'')"
          @blur="scaleEvent"
        />
        <i
          class="el-icon-plus icon"
          title="放大"
          @click="scaleChange('next')"
        />
      </div>
      <div class="opt">
        <img
          class="icon"
          src="@/assets/images/pdf/download.png"
          alt=""
          title="下载"
        />
        <img
          class="icon"
          src="@/assets/images/pdf/print.png"
          alt=""
          title="打印"
          @click="print"
        />
      </div>
    </div>
    <div v-loading="loading" class="pdfArea" :element-loading-text="remindShow">
      <div ref="pdfContent" class="pdfContent" :style="scaleFun">
        <pdf
          v-for="i in pageCount"
          :key="i"
          ref="pdf"
          class="pdf"
          :src="src"
          :page="i"
          @loaded="loadPdfHandler"
        >
        </pdf>
      </div>
    </div>
  </div>
</template>

<script>
import pdf from 'vue-pdf-signature'

export default {
  components: {
    pdf
  },
  data() {
    return {
      remindShow: '加载文件中，文件较大请耐心等待...',
      intervalID: '',
      // ----- vuepdf -----
      // src静态路径: /static/xxx.pdf
      // src服务器路径: 'http://.../xxx.pdf'
      src: '/medical/zooTeam/algorithm-101-v1.1.0.pdf',
      // 当前页数
      currentPage: 1,
      // 总页数
      pageCount: 0,
      scale: '100%',
      // 加载进度
      loadedRatio: 0,
      loading: true
    }
  },
  computed: {
    scaleFun: function() {
      const scale = parseInt(this.scale.split('%')[0], 10) / 100
      const width = `${610 * scale}px`
      return `width:${width}`
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    // 路由守卫刚刚进入是没有实例化界面的，不能使用this
    next((vm) => {
      // vm.src = pdf.createLoadingTask(
      //   `backEnd/api/5KXQFWYH/Order/orderLabel?orderId=${vm.$route.query.orderId}`
      // )
      // vm.src.promise.then((pdf) => {
      //   console.log('pageCount')
      //   vm.pageCount = pdf.numPages
      // })
      if (vm.$route.query.labeFormat === 'PDF') {
        // type 0 订单面单 1 发票 2 扫码面单
        const type = vm.$route.query.type || 0
        switch (parseInt(type, 10)) {
          case 0 :
          vm.src = pdf.createLoadingTask(
            `backEnd/api/5KXQFWYH/Order/orderLabel?orderId=${vm.$route.query.orderId}`
          )
          break
          case 1 :
          vm.src = pdf.createLoadingTask(
            `backEnd/api/5KXQFWYH/Order/orderInvoice?orderId=${vm.$route.query.orderId}`
          )
          break
          case 2 :
          vm.src = pdf.createLoadingTask(
            `backEnd/api/5KXQFWYH/Order/getReprintLabel?trackingNmber=${vm.$route.query.trackingNmber}`
          )
          break
        }
        vm.src.promise.then((pdf) => {
          console.log('pageCount')
          vm.pageCount = pdf.numPages
        })
      }
    })
  },
  mounted() {
    console.log('mounted')
    this.listenerFunction()
    window.addEventListener('beforeprint', () => {
      console.log('beforeprint ')
      this.remindShow = '加载文件中，文件较大请耐心等待...'
      this.loading = false
    })
  },
  beforeDestroy() {
    document.removeEventListener()
  },
  methods: {
    // 监听滚动条事件
    listenerFunction() {
      document
        .querySelector('.pdfArea')
        .addEventListener('scroll', this.setScrollPage, true)
    },
    // 设置滚动页码
    setScrollPage() {
      const scrollTop = document.querySelector('.pdfArea').scrollTop
      const height = document.querySelector('.pdf').offsetHeight // 340875
      // const height = document.querySelector('.pdfArea').offsetHeight
      const scrollHeight = this.pageCount * (height + 10) - 10
      console.log('scrollTop, scrollHeight', scrollTop, scrollHeight)
      this.currentPage =
        Math.floor(this.pageCount * (scrollTop / scrollHeight)) + 1
    },
    // 输入页码时校验
    inputEvent() {
      if (this.currentPage > this.pageCount) {
        // 1. 大于max
        this.currentPage = this.pageCount
      } else if (this.currentPage < 1) {
        // 2. 小于min
        this.currentPage = 1
      }
      this.goPager()
    },
    goPager() {
      const height = document.querySelector('.pdf').offsetHeight
      const sumHeight = this.currentPage * (height + 10) - 10
      document.querySelector('.pdfArea').scrollTop = sumHeight
    },

    // pdf加载时
    loadPdfHandler() {
      console.log('loadPdfHandler')
      // 加载的时候先加载第一页
      this.currentPage = 1
      this.loading = false
    },
    print() {
      this.remindShow = '打印文件准备中，文件较大请耐心等待...'
      this.loading = true
      this.$refs.pdf[0].print()
    },
    scaleChange(type) {
      let num = parseInt(this.scale.split('%')[0], 10)
      if (type === 'prev') {
        num = num > 50 ? num - 10 : 50
      } else {
        num = num < 150 ? num + 10 : 150
      }
      this.scale = `${num}%`
    },
    scaleEvent() {
      let num = parseInt(this.scale.split('%')[0], 10)
      if (num < 50) {
        num = 50
      } else if (num > 150) {
        num = 150
      }
      this.scale = `${num}%`
    }
  }
}
</script>

<style scoped lang="less">
#container {
  overflow: auto;
  width: 100%;
  height: 100%;
  background: #525659;
  border-radius: 0px 0px 0px 0px;
  .toolbar {
    width: 100%;
    height: 60px;
    background: #323639;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
  }
  .pdf-name {
    width: auto;
    height: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 60px;
    margin-left: 40px;
  }
  .pdfArea {
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    .pdfContent {
      width: 610px;
      margin: 0 auto;
    }
    .pdf {
      width: 100%;
      & + .pdf {
        margin-top: 10px;
      }
    }
  }
  .pager-content {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .pager {
    width: 30px;
    height: 24px;
    background: #000000;
    color: #ffffff;
    opacity: 1;
    border: 0;
    text-align: center;
    &:focus-visible {
      outline: 0;
    }
  }
  .split {
    width: 8px;
    height: 10px;
    opacity: 1;
    padding: 0 10px;
    font-size: 12px;
    line-height: 10px;
  }
  .pager-count {
    width: auto;
    height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 21px;
  }
  .line {
    width: 0px;
    height: 24px;
    opacity: 1;
    border: 1px solid #999999;
    margin: 0 20px;
  }
  .icon {
    font-size: 18px;
    color: #ffffff;
    width: 18px;
    height: 18px;
    cursor: pointer;
    & + .icon {
      margin-left: 20px;
    }
  }
  .scale {
    margin: 0 10px;
    width: 60px;
  }
  .opt {
    margin-right: 40px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
